@use 'colors';
@use 'breakpoints';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  .paper {
    background-color: colors.$white;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.75);
    min-width: 128px;

    h2 {
      color: colors.$gray;
      text-align: center;
    }

    .barContainer {
      width: 100%;
      margin: 8px 0px;

      .green {
        background-color: colors.$green;
      }

      .red {
        background-color: colors.$red;
      }

      .gray {
        background-color: colors.$gray;
      }

      & > * {
        display: inline-block;
        min-height: 16px;
      }
    }
  }
}
